/*-------------------------
    Banner
-------------------------*/
.banner {
    padding: 123px 0 0;
    position: relative;
    z-index: 2;
    min-height: 0px;

    @media #{$laptop-device} {
        padding: 123px 0 0;
    }

    @media #{$smlg-device} {
        padding: 94px 0 0;
        min-height: 100%;
    }

    @media #{$md-layout} {
        padding: 120px 0 0;
    }

    @media #{$sm-layout} {
        padding: 100px 0 0;
    }

    .banner-content {
        @media #{$md-layout} {
            margin-bottom: 90px;
        }

        @media #{$sm-layout} {
            margin-bottom: 60px;
        }

        @media #{$large-mobile} {
            margin-bottom: 40px;
        }

        .title {
            font-size: 80px;
            font-weight: 700;

           
                // background-image:  radial-gradient(circle,  #aed0d0,#2ac8ce);
                background: linear-gradient(-45deg, #00b461 0%, #00adb4, #23a6d5, #23d5ab);
                background-size: 400% 400%;
	            // height: 100vh;
                animation: gradient 10s ease infinite;
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text;            

                .youtube{
                // background-image:  radial-gradient(circle, rgb(232, 51, 51), rgba(241, 9, 9, 0.835));
                background: linear-gradient(-45deg, #ee7752, #e72416, #ee7752, #e73c7e);
                background-size: 400% 400%;
                // height: 100vh;
                animation: gradient 10s ease infinite;
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text; 

                }

            @media #{$laptop-device} {
                font-size: 75px;
            }

            @media #{$smlg-device} {
                font-size: 60px;
            }

            @media #{$md-layout} {
                font-size: 55px;
            }

            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }

        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 40px;
            width: 80%;
        }

        .axil-btn {
            //display: none;
            padding: 15px 30px;
            box-shadow: 0 44px 84px -24px var(--color-primary2);

            @media #{$md-layout} {
                padding: 20px 35px;
            }

        }

        .btn-group {
            display: flex;
            align-items: center;

            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }

            a {
                margin-right: 40px;

                @media #{$large-mobile} {
                    margin-right: 0;
                    margin-bottom: 30px;
                }

                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }

        .about-btn {
            font-weight: 500;
            color: var(--color-primary);
            position: relative;
            padding-left: 50px;

            &::before {
                content: "";
                height: 1px;
                width: 34px;
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                transition: var(--transition);
            }

            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    .banner-thumbnail {
        position: relative;
    }

    .banner-social {
        display: flex;
        align-items: center;

        .border-line {
            flex: 1;
            height: 1px;
            width: 100%;
            background-color: var(--color-body);
            margin-right: 30px;

            @media #{$large-mobile} {
                display: none;
            }
        }

        .social-icon {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            li {
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                a {
                    color: var(--color-body);

                    i,
                    svg {
                        margin-right: 8px;
                        font-size: 20px;
                        color: var(--color-gray-4);
                        transition: var(--transition);
                    }

                    &:hover {
                        color: var(--color-primary);

                        i {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }

    &.banner-style-1 {
        .banner-content {
            margin-top: 60px;

            @media #{$smlg-device} {
                padding-bottom: 100px;
            }

            @media #{$md-layout} {
                margin-top: 0;
                padding-bottom: 0;
            }

            .title {
                font-size: 78px;
                line-height: 1;

                @media #{$laptop-device} {
                    font-size: 68px;
                }

                @media #{$smlg-device} {
                    font-size: 60px;
                }

                @media #{$md-layout} {
                    font-size: 55px;
                }

                @media #{$sm-layout} {
                    font-size: 40px;
                }
            }
        }

        .banner-thumbnail {
            z-index: 5;

            .large-thumb {
                @media #{$laptop-device} {
                    img {
                        width: 74%;
                    }
                }

                @media #{$sm-layout} {
                    // display: none;
                }
            }

            .large-thumb-2 {
                text-align: right;
                margin-top: -260px;
                margin-right: -50px;

                @media #{$laptop-device} {
                    img {
                        width: 85%;
                    }
                }

                @media #{$smlg-device} {
                    margin-top: -200px;
                    margin-right: -30px;

                    img {
                        width: 80%;
                    }
                }

                @media #{$md-layout} {
                    margin-top: -260px;
                    margin-right: 0;
                }

                @media #{$sm-layout} {
                    margin-top: 0;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                }
            }

            .shape-group {
                .shape {
                    position: absolute;

                    &.shape-1 {
                        top: -25px;
                        right: 20px;
                        left: auto;

                        @media #{$laptop-device} {
                            right: 0;
                        }

                        @media #{$smlg-device} {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.banner-style-2 {
        margin: 160px 95px 0;
        padding: 100px 0 80px 100px;
        background-color: var(--color-primary);
        border-radius: 60px;
        overflow: hidden;
        min-height: auto;

        @media #{$laptop-device} {
            padding: 80px 0 60px 80px;
            min-height: 600px;
            margin: 130px 30px 0;
        }

        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 60px 0 40px 60px;
            // min-height: 600px;
        }

        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }

        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 50px 0 30px;
        }

        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }

        .banner-content {
            margin-bottom: 0;
            width: 100%;
            // height: 100px;

            @media only screen and (min-width: 2200px) {
                width: 100%;
            }

            @media #{$laptop-device} {
                width: 100%;
            }

            @media #{$smlg-device} {
                width: 100%;
            }

            @media #{$md-layout} {
                width: 100%;

            }

            @media #{$sm-layout} {
                width: 100%;

                li {
                    text-align: center;
                }
            }

            @media #{$large-mobile} {
                width: 100%;

                li {
                    text-align: center;
                }
            }

            .dragndropUL {
                padding-left: 0%;
                list-style-type: none;

            }










            .title {
                // color: var(--color-white);
                // background-image:  radial-gradient(circle, #53d6da, #9ff4f7);
                background: linear-gradient(-45deg,#e9e8e8, #68fff7);
                background-size: 400% 400%;
	            // height: 100vh;
                animation: gradient 10s ease infinite;
                color: transparent;
                background-clip: text;
                -webkit-background-clip: text;        
                margin-bottom: 40px;
                padding-right: 10px;
                width: 85%;

                @media #{$laptop-device} {
                    width: 100%;
                }

                @media #{$smlg-device} {
                    width: 120%;
                }

                @media #{$md-layout} {
                    width: 60%;
                    font-size: 300%;
                }
                @media #{$sm-layout} {
                    width: 100%;
                    // font-size: 300%;
                }
            }
        }
    }
    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    &.banner-style-3 {
        padding: 80px 0 210px;

        @media #{$laptop-device} {
            padding: 30px 0 20px;
        }

        @media #{$smlg-device} {
            padding: 45px 0 50px;
        }

        @media #{$sm-layout} {
            padding: 45px 0 50px;
        }

        .banner-content {
            position: relative;
            z-index: 1;

            .subtitle {
                font-size: 20px;
                font-weight: 700;
                color: var(--color-rose);
                letter-spacing: 0.10em;
                font-family: var(--font-primary);
                margin-bottom: 30px;

                @media #{$md-layout} {
                    font-size: 16px;
                }
            }

            .title {
                margin-bottom: 60px;

                @media #{$md-layout} {
                    margin-bottom: 40px;
                }
            }
        }

        .banner-thumbnail {
            margin-right: -120px;
            text-align: right;

            @media #{$laptop-device} {
                margin-right: 0;
            }

            @media #{$smlg-device} {
                margin-right: 0;
            }

            @media #{$md-layout} {
                text-align: left;
            }
        }
    }

    &.banner-style-4 {
        background: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
        padding: 310px 0 100px;

        @media #{$laptop-device} {
            padding: 250px 0 100px;
        }

        @media #{$smlg-device} {
            padding: 250px 0 100px;
        }

        @media #{$md-layout} {
            padding: 180px 0 80px;
            min-height: 100%;
        }

        @media #{$sm-layout} {
            padding: 150px 0 60px;

        }

        .banner-content {
            margin-bottom: 200px;
            position: relative;
            z-index: 1;

            @media #{$smlg-device} {
                margin-bottom: 100px;
            }

            @media #{$md-layout} {
                margin-bottom: 0;

            }

            .title {
                width: 60%;
                margin-bottom: 50px;

                @media #{$laptop-device} {
                    font-size: 70px;
                    width: 55%;
                }

                @media #{$smlg-device} {
                    font-size: 70px;
                }

                @media #{$md-layout} {
                    font-size: 60px;
                    width: 100%;
                }

                @media #{$sm-layout} {
                    font-size: 50px;
                }

                @media #{$large-mobile} {
                    font-size: 40px;
                    margin-bottom: 30px;
                }
            }

            p {
                font-size: var(--font-body-1);
                width: 50%;
                margin-bottom: 40px;

                @media #{$md-layout} {
                    width: 100%;
                }
            }
        }

        .banner-thumbnail {
            position: absolute;
            top: 45%;
            right: 0;
            transform: translateY(-50%);

            @media #{$laptop-device} {
                right: -300px;

                img {
                    width: 85%;
                }
            }

            @media #{$smlg-device} {
                right: -570px;

                img {
                    width: 60%;
                }
            }

            @media #{$md-layout} {
                position: initial;
                transform: translateY(0);
                text-align: center;
                margin: -20px 0 20px;

                img {
                    width: 100%;
                }
            }

            @media #{$large-mobile} {
                margin: 0 0 20px;
            }
        }
    }

    &.banner-style-5 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 310px 0 0;

        @media #{$laptop-device} {
            padding: 250px 0 0;
            min-height: 750px;
        }

        @media #{$smlg-device} {
            padding: 230px 0 0;
            min-height: 750px;
        }

        @media #{$md-layout} {
            padding: 170px 0 80px;
            min-height: 100%;
        }

        @media #{$sm-layout} {
            padding: 150px 0 60px;
            min-height: 100%;
        }

        &::after {
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgba(41, 41, 48, 0.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }

        .banner-content {
            @media #{$md-layout} {
                margin-bottom: 40px;
            }

            .title {
                color: var(--color-white);
                margin-bottom: 50px;

                @media #{$smlg-device} {
                    font-size: 70px;
                }

                @media #{$md-layout} {
                    font-size: 60px;
                }

                @media #{$sm-layout} {
                    font-size: 50px;
                }

                @media #{$large-mobile} {
                    font-size: 40px;
                    margin-bottom: 40px;
                }
            }

            .axil-btn {
                color: var(--color-text-dark);
                box-shadow: none;
            }
        }

        .banner-form {
            display: flex;
            justify-content: flex-end;
            margin-bottom: -140px;

            @media #{$md-layout} {
                margin-bottom: 0;
                display: block;
            }

            .contact-form-box {
                max-width: 450px;

                @media #{$md-layout} {
                    max-width: 100%;
                }

            }

            .title {
                letter-spacing: -0.045em;
            }
        }
    }
}

// .drag-image{
//     border: 1px dashed #fff;
//     border-radius: 30px;
// }

//css for drag and drop
.ant-upload-wrapper .ant-upload-drag {
    border: 1px dashed #fff;
    position: relative;
    max-width: 100%;
    border-style: hidden;
    transition: border-color 0.3s;
    text-align: center;
    height: 200px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: var(--color-dark) 0px 25px 50px -12px;
    //box-shadow: 0 44px 84px -24px var(--color-primary2);
    // margin-bottom: 5%;
    border-radius: 30px;
    font-weight: 400;
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    transition: var(--transition);
    position: relative;
    z-index: 1;
    overflow: hidden;
    // &.btn-borderd {
    //     border: var(--border-gray);
    //     color: var(--color-dark);
    //     background-color: transparent;
    //     &:hover {
    //         background-color: var(--color-primary);
    //         color: var(--color-white);
    //         border-color: var(--color-primary);

    //     }
    // }


    // background-color: var(--color-white);
    // color: var(--color-white);
    // border: 1px solid var(--color-primary);
    // overflow: hidden;

    &::after {

        content: "";
        height: 1000px;
        width: 1000px;
        background-color: var(--color-primary);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0);
        transition: 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        z-index: -1;









    }

    &:hover {
        background-color: var(--color-link);
        // border-color: var(--color-link);

        &::after {
            transform: translateY(-50%) translateX(-50%) scale(1);

        }
    }

    // &.light {
    //     display: none;
    // }
    // &.dark {
    //     display: block;
    // }




}

//    //css for drag and drop
//    :where(.css-dev-only-do-not-override-1n7nwfa).ant-upload-wrapper .ant-upload-drag {
//     // border: 1px dashed #fff;
//     position: relative;
//     // border-style: hidden;
//     // transition: border-color 0.3s;
//     text-align: center;
//     height: 200px;
//     width: 100%;
//     background-color: #F5F5F7;
//     box-shadow:  black 0px 25px 50px -12px;    //box-shadow: 0 44px 84px -24px var(--color-primary2);
//     // margin-bottom: 1%;
//     border-radius: 30px;
//     font-weight:400;

//     // display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

//     text-align: center;
//     transition: var(--transition);
//     position: relative;
//     z-index: 1;
//     overflow: hidden;
//     // &.btn-borderd {
//     //     // border: var(--border-gray);
//     //     color: var(--color-dark);
//     //     // background-color: transparent;
//     //     &:hover {
//     //         background-color: var(--color-primary);
//     //         color: var(--color-white);
//     //         border-color: var(--color-dark);
//     //     }
//     // }


//     // background-color: var(--color-white);
//     // color: var(--color-white);
//     // border: 2px solid var(--color-dark);
//     // overflow: hidden;

//     &::after {
//         content: "";
//         height: 1000px;
//         width: 1000px;
//         background-color: var(--color-primary);
//         border-radius: 50%;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translateY(-50%) translateX(-50%) scale(0);
//         transition: 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
//         z-index: -1;
//     }
//     &:hover {
//         // background-color: var(--color-link);
//         border-color: var(--color-dark);
//         &::after {
//             transform: translateY(-50%) translateX(-50%) scale(1);
//         }
//     }

//     // &.light {
//     //     display: none;
//     // }
//     // &.dark {
//     //     display: block;
//     // }




//   }


.bannerBtn {
    margin-top: 2%;
    text-align: center;
}

//   .drag-image span{
//     font-size: 14px;
//     font-weight: 300;
//     color: #fff;
//     margin: 10px 0 15px 0;
//     max-width: 100%;
//   }

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
    line-height: 1.5714285714285714;
    flex: auto;
    transition: all 0.3s;
    max-width: 420px;

    @media #{$smlg-device} {
        max-width: 380px;
    }

    @media #{$md-layout} {
        max-width: 420px;
    }

    @media #{$sm-layout} {
        max-width: 390px;
    }

    @media #{$large-mobile} {
        max-width: 190px;
    }



}

.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    margin: 10px 0 15px 0;
    max-width: 100%;
    text-align: justify;
    text-overflow: ellipsis;

}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error .ant-upload-icon .anticon {
    color: #dc4446;
}

//   .dragndropUL{

//   }

.ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 0px 0;
}






.upload-text {
    transition: 1s;
    font-size: auto;
    color: rgba(57, 57, 57, 0.719);
    font-weight: 500;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;

}

.drag-image.active {
    border: 2px solid #fff;
}

.iconlight {

    // display: none;
    // font-size: 5px;
    width: 1%;
    // height: 100%;
    color: #fff;
    vertical-align: middle;
    margin-top: 3%;




}


.ant-upload-wrapper .ant-upload-drag .ant-upload-btn :hover {



    .icondark {


        display: inline;
    }

    .iconlight {


        display: none;
    }

    .upload-text {



        // transition: all 1s;
        // transform: translateY(-50%) translateX(-50%) scale(0);
        // transition: -1s;
        // z-index: -1;
        transition: all 0s;
        color: var(--color-white);
    }

}

.icondark {

    // font-size: 5px;
    display: none;
    width: 10%;
    // height: 100%;
    color: #fff;
    vertical-align: middle;
    margin-top: 3%;


}

.icondark2 {
    // font-size: 5px;
    display: none;
    width: 10%;
    // height: 100%;
    color: #fff;
    vertical-align: middle;
    margin-top: 3%;


}






.drag-image h6 {
    font-size: 20px;
    font-weight: 300;
    color: #fff;
}

//   .drag-image span{
//     font-size: 14px;
//     font-weight: 300;
//     color: #fff;
//     margin: 10px 0 15px 0;
//     max-width: 100%;
//   }
.drag-image button {
    padding: 10px 25px;
    font-size: 14px;
    font-weight: 300;
    border: none;
    outline: none;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
}


.drag-image .icon {
    padding-top: 30px;
}

.drag-image button:hover {
    background-color: #fff;
    color: red;
}

.drag-image img {

    width: 12%;
    margin-bottom: 15px;
    padding-top: 0px;
    object-fit: cover;
    border-radius: 5px;
}

.testtt {
    height: 50px;
    width: 50px;

}

//   :where(.css-dev-only-do-not-override-1n7nwfa).ant-upload-wrapper .ant-upload-drag {
//     position: relative;
//     height: 100%;
//     width: 100%;
//     text-align: center;
//     background: rgba(0, 0, 0, 0.02);
//     border: none;
//     border-radius: 8px;
//     cursor: pointer;
//     transition: border-color 0.3s;
// }




// .box__dragndrop,
// .box__uploading,
// .box__success,
// .box__error {
//   display: none;
// }


// @import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700&display=swap');





// .droppable {
//   max-width: 400px;
//   width: 100%;
//   height: 360px;
//   background-color: #fff;
//   margin: auto;
//   display: flex;
//   flex-wrap: wrap;
//   position: relative;
//   border-radius: 12px;
//   align-content: flex-start;
//   overflow: hidden;
//   box-shadow: 0 10px 20px rgba(0,0,0,.1);
//   transition: .3s;
// }

// .droppable.is-dragging .list > * {
//   pointer-events: none;
// }

// .list {
//   overflow: auto;
//   width: 100%;
//   height: 100%;
//   padding: 10px 0;
//   scroll-behavior: smooth; 
// }

// .droppable.is-over {
//  box-shadow: 0px 10px 26px rgba(30, 24, 37, 0.33), 0 0 0px 15px rgba(53, 40, 66, 0.07);
// }

// .item {
//   padding: 10px 20px;
//   width: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   overflow: hidden;
// }

// .item > * {
//   opacity: 0;
// }

// .item-img {
//   width: 60px;
//   height: 48px;
//   border-radius: 4px;
//   overflow: hidden;
// }

// .loaded-image {
//   position: absolute;
//   top: 0;
//   transform-origin: center;
//   left: 0;
//   opacity: 0;
//   width: 60px;
//   height: 48px;
//   border-radius: 4px;
//   overflow: hidden;
//   background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
// }

// .loaded-image > img {
//   opacity: 0;
//   filter: blur(10px);
// }

// .loaded-image > span {
//   position: absolute;
//   left: 50%;
//   opacity: 0;
//   width: 32px;
//   height: 32px;
//   transform: translate(-50%, -50%);
//   transform-origin: center;
//   top: 50%;
// }

// .loaded-image svg {
//   width: 32px;
//   height: 32px;
// }

// img {
//   object-fit: cover;
//   object-position: center;
//   width: 100%;
//   height: 100%;
// }

// img:before {
//   position: absolute;
//   content: "";
//   height: 100%;
//   width: 100%;
//   background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
// }

// .item-details {
//   flex: 1;
//   padding-left: 16px;
//   overflow: hidden;
//   padding-right: 32px;
// }

// .item-name {
//   font-size: 15px;
//   margin-bottom: 4px;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   font-weight: 600;
// }

// .item-size {
//   font-size: 11px;
//   letter-spacing: 1px;
//   color: #9699b3;
// }

// .item-delete {
//   border: 0;
//   background-color: transparent;
//   width: 18px;
//   height: 18px;
//   padding: 0;
//   margin-right: 6px;
//   color: transparent;
//   white-space: nowrap;
//   outline: 0;
//   cursor: pointer;
//   position: relative;
// }

// .item-delete:before {
//   content: "";
//   position: absolute;
//   left: 0;
//   transition: .3s;
//   top: 0;
//   z-index: 1;
//   width: 100%;
//   height: 100%;
//   background-size: cover;
//   background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23bac1cb' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 438.5 438.5'%3e%3cpath d='M417.7 75.7A8.9 8.9 0 00411 73H323l-20-47.7c-2.8-7-8-13-15.4-18S272.5 0 264.9 0h-91.3C166 0 158.5 2.5 151 7.4c-7.4 5-12.5 11-15.4 18l-20 47.7H27.4a9 9 0 00-6.6 2.6 9 9 0 00-2.5 6.5v18.3c0 2.7.8 4.8 2.5 6.6a8.9 8.9 0 006.6 2.5h27.4v271.8c0 15.8 4.5 29.3 13.4 40.4a40.2 40.2 0 0032.3 16.7H338c12.6 0 23.4-5.7 32.3-17.2a64.8 64.8 0 0013.4-41V109.6h27.4c2.7 0 4.9-.8 6.6-2.5a8.9 8.9 0 002.6-6.6V82.2a9 9 0 00-2.6-6.5zm-248.4-36a8 8 0 014.9-3.2h90.5a8 8 0 014.8 3.2L283.2 73H155.3l14-33.4zm177.9 340.6a32.4 32.4 0 01-6.2 19.3c-1.4 1.6-2.4 2.4-3 2.4H100.5c-.6 0-1.6-.8-3-2.4a32.5 32.5 0 01-6.1-19.3V109.6h255.8v270.7z'/%3e%3cpath d='M137 347.2h18.3c2.7 0 4.9-.9 6.6-2.6a9 9 0 002.5-6.6V173.6a9 9 0 00-2.5-6.6 8.9 8.9 0 00-6.6-2.6H137c-2.6 0-4.8.9-6.5 2.6a8.9 8.9 0 00-2.6 6.6V338c0 2.7.9 4.9 2.6 6.6a8.9 8.9 0 006.5 2.6zM210.1 347.2h18.3a8.9 8.9 0 009.1-9.1V173.5c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a8.9 8.9 0 00-9.1 9.1V338a8.9 8.9 0 009.1 9.1zM283.2 347.2h18.3c2.7 0 4.8-.9 6.6-2.6a8.9 8.9 0 002.5-6.6V173.6c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a9 9 0 00-6.6 2.6 8.9 8.9 0 00-2.5 6.6V338a9 9 0 002.5 6.6 9 9 0 006.6 2.6z'/%3e%3c/svg%3e");
// }

// .item-delete:after {
//   content: "";
//   position: absolute;
//   opacity: 0;
//   left: 50%;
//   top: 50%;
//   width: 100%;
//   height: 100%;
//   transform: translate(-50%, -50%) scale(0);
//   background-color: #f3dbff;
//   border-radius: 50%;
//   transition: .3s;
// }

// .item-delete:hover:after {
//   transform: translate(-50%, -50%) scale(2.2);
//   opacity: 1;
// }

// .item-delete:hover:before {
//   background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%234f555f' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 438.5 438.5'%3e%3cpath d='M417.7 75.7A8.9 8.9 0 00411 73H323l-20-47.7c-2.8-7-8-13-15.4-18S272.5 0 264.9 0h-91.3C166 0 158.5 2.5 151 7.4c-7.4 5-12.5 11-15.4 18l-20 47.7H27.4a9 9 0 00-6.6 2.6 9 9 0 00-2.5 6.5v18.3c0 2.7.8 4.8 2.5 6.6a8.9 8.9 0 006.6 2.5h27.4v271.8c0 15.8 4.5 29.3 13.4 40.4a40.2 40.2 0 0032.3 16.7H338c12.6 0 23.4-5.7 32.3-17.2a64.8 64.8 0 0013.4-41V109.6h27.4c2.7 0 4.9-.8 6.6-2.5a8.9 8.9 0 002.6-6.6V82.2a9 9 0 00-2.6-6.5zm-248.4-36a8 8 0 014.9-3.2h90.5a8 8 0 014.8 3.2L283.2 73H155.3l14-33.4zm177.9 340.6a32.4 32.4 0 01-6.2 19.3c-1.4 1.6-2.4 2.4-3 2.4H100.5c-.6 0-1.6-.8-3-2.4a32.5 32.5 0 01-6.1-19.3V109.6h255.8v270.7z'/%3e%3cpath d='M137 347.2h18.3c2.7 0 4.9-.9 6.6-2.6a9 9 0 002.5-6.6V173.6a9 9 0 00-2.5-6.6 8.9 8.9 0 00-6.6-2.6H137c-2.6 0-4.8.9-6.5 2.6a8.9 8.9 0 00-2.6 6.6V338c0 2.7.9 4.9 2.6 6.6a8.9 8.9 0 006.5 2.6zM210.1 347.2h18.3a8.9 8.9 0 009.1-9.1V173.5c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a8.9 8.9 0 00-9.1 9.1V338a8.9 8.9 0 009.1 9.1zM283.2 347.2h18.3c2.7 0 4.8-.9 6.6-2.6a8.9 8.9 0 002.5-6.6V173.6c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a9 9 0 00-6.6 2.6 8.9 8.9 0 00-2.5 6.6V338a9 9 0 002.5 6.6 9 9 0 006.6 2.6z'/%3e%3c/svg%3e");
// }

// .droppable.is-over .item {
//   opacity: 0.5;
//   transition: .3s;
// }

// .info {
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
//   opacity: 0;
//   transition: .4s;
// }


// .list:empty + .info {
//   opacity: 1;
// }

// .info-item {
//   position: absolute;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   padding: 20px;
// }

// .info-svg {
//   width: 80px;
//   margin-bottom: 20px;
// }

// .info-text {
//   font-size: 20px;
//   font-weight: 600;
// }


//back to top css

.ant-back-top .ant-back-top-content {
    width: 40px;
    height: 40px;
    overflow: hidden;
    color: var(--color-white);
    // text-align: center;
    background-color: var(--color-primary2);
    border-radius: 40px;
    transition: all 0.2s;
    vertical-align: middle;

}

.anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: middle;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-bottom: 2px;
    ;
}

.ant-back-top .ant-back-top-content:hover {
    color: var(--color-white);
    // text-align: center;
    background-color: var(--color-primary);
}

// .ant-back-top {
//     bottom: 100px;
//   }
// .ant-back-top-inner {
//     height: 40px;
//     width: 40px;
//     line-height: 40px;
//     border-radius: 4px;
//     background-color: #1088e9;
//     color: #fff;
//     text-align: center;
//     font-size: 20px;
//   }



.thumbgen-vector {
    width: 70%;

    @media #{$smlg-device} {
        width: 100%;
    }

    @media #{$md-layout} {
        width: 100%;
    }

    @media #{$sm-layout} {
        width: 140%;
    }

    @media #{$large-mobile} {
        width: 120%;
    }

}