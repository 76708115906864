.slider {
  width: 700px;
  height: auto;
  border-radius: 20px;
  text-align: center;
}

.slider-frame {
  justify-content: center;
}

.slider-in {
  max-width: 720px;
  height: auto;
  vertical-align: middle;
}

.banner .banner-style-3 .thumbgenface {
  padding: 80px 0 150px;
}

.addimage {
  @media #{$laptop-device} {
    width: 70px;
  }

  @media #{$smlg-device} {
    width: 30px;
  }

  @media #{$md-layout} {
    width: 80px;
  }

  @media #{$sm-layout} {
    width: 60px;
  }
}

.project-grid {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.imagelist {
  border-radius: 20px;
}

// .item1img {
//     display: inline-block;
//     width: 80%;
//     float: left;
//     margin-right: 10px;

// }

.imageupload2 {
  display: block;
  width: 200px;
}

.item2img {
  padding-left: 0px;
  float: left;
}

.thumbgenbtn3 {
  width: auto;
}

.image-preview-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 355px;
  background-color: transparent;
  margin-top: 140px;
  // margin-bottom: 40px;

  @media #{$laptop-device} {
    height: 355px;
  }

  @media #{$smlg-device} {
    height: 285px;
  }

  @media #{$md-layout} {
    height: 230px;
  }

  @media #{$sm-layout} {
    height: 500px;
    display: grid;
    text-align: center;
    margin-top: 20px;
  }
}

.image-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;

  // display: flex;
  // flex-direction: column;
  // justify-content: ce;
  list-style: none;
  height: 100%;
  overflow-y: auto;
  flex: 1;
  max-width: 390px;
  min-width: 390px;
  margin-bottom: 130px;
  margin-top: 10px;
  border-radius: 15px;
  // border: 1px solid #333;
  // margin-left: 10px;
  padding-left: 0px;
  padding-top: 10px;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: var(--color-dark) 0px 25px 50px -12px;
  // margin-top: 130px;
  margin-left: 10px;

  @media #{$laptop-device} {
    max-width: 390px;
    min-width: 390px;
  }

  @media #{$smlg-device} {
    max-width: 390px;
    min-width: 390px;
  }

  @media #{$md-layout} {
    max-width: 330px;
    min-width: 330px;
  }

  @media #{$sm-layout} {
    width: 340px;
    text-align: center;
    margin-left: 0px;
    margin-bottom: 125px;
    margin-top: 10px;
    height: 68%;
    min-height: 68%;
  }
}

// .imgcontainer {
//     position: relative;
// }

.image-list li {
  margin-bottom: 10px;
  margin-right: 4px;
  margin-left: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.image-list li:hover {
  transform: scale(1.03);
}

.image-list li img {
  display: block;
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 15px;

  @media #{$md-layout} {
    width: 150px;
    height: 90px;
  }
}

//

.selected-image {
  max-width: 630px;
  max-height: 500px;
  object-fit: contain;
  border-radius: 15px;

  // border: 3px solid #333;
  // margin-left: 300px;
  // box-shadow: var(--color-dark) 0px 25px 50px -12px;
  // position: absolute;
  // z-index: 1;
  @media #{$laptop-device} {
    max-width: 630px;
    max-height: 500px;
  }

  @media #{$smlg-device} {
    max-width: 500px;
  }

  @media #{$md-layout} {
    max-width: 400px;
  }

  @media #{$sm-layout} {
    max-width: 330px;
    margin-bottom: 30px;
  }
}

.layer2 {
  position: absolute;
}

.layer1 {
  box-shadow: var(--color-dark) 0px 25px 50px -12px;
}

// .selected-image2 {
//     max-width: 630px;
//     max-height: 500px;
//     object-fit: contain;
//     border-radius: 15px;
//     // border: 3px solid #333;
//     // margin-left: 300px;
//     box-shadow: var(--color-dark) 0px 25px 50px -12px;
//     position: absolute;
//     z-index: 2;
// }

// .imgcontainer {
//     position: relative;

// }

.thumbnail1 {
  border-radius: 20px;
}

.more-project-btn .axil-btn {
  background-color: var(--color-primary);
}

// ::-webkit-scrollbar {
//     width: 4px;
//     height: auto;

// }

// // ::-webkit-scrollbar-track-piece {
// //     background-color: #C2D2E4;
// // }

// ::-webkit-scrollbar-thumb:vertical {

//     background-color: #00adb4;
//     border-radius: 50px;

// }

.bguploadbtn {
  display: none;
}

.finaltext {
  text-align: center;
}

.finaltext1 {
  font-weight: 500;
  color: rgb(70, 70, 70);
  @media #{$sm-layout} {
    margin-top: 75px;
  }
}

.downimg {
  box-shadow: var(--color-dark) 0px 25px 50px -12px;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;

  // margin-bottom: 20px;
}

.thumboverlay {
  transition: all 0.1s ease-in-out;
  border-radius: 10px;
}

// .downimglink :hover {
//     transform: scale(1.02);
// }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #0000008c;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
}
.overlayLoading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  transition: 0.5s ease;
  background-color: #0000008c;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
}

.overlaybtn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;

  border-radius: 10px;
}

.overlaycontainer:hover .downimg {
  transform: scale(1.02);
}

.overlaycontainer:hover .thumboverlay {
  transform: scale(1.02);
}

.overlaycontainer:hover .overlay {
  transform: scale(1.02);
  opacity: 1;
}
.overlaycontainer:hover .overlayLoading {
  transform: scale(1.02);
}

// .overlaycontainer:hover .thumbbtn {
//     transform: scale(1.02);
//     opacity: 0;
// }
.overlayimg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  border-radius: 10px;
}

.text {
  color: rgb(220, 220, 220);
  font-size: auto;
  font-weight: 500;
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.overlaycontainer {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.thumbbtn {
  position: relative;
  top: 10%;
  left: 48%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  border-radius: 5px;
  opacity: 80%;
  width: 30px;
  background-color: var(--color-white);
  padding: 3px;
  color: var(--color-white);
  // border: 1px solid var(--color-primary);
  overflow: hidden;

  @media #{$laptop-device} {
    width: 30px;
  }

  @media #{$smlg-device} {
    width: 25px;
  }

  @media #{$md-layout} {
    width: 20px;
  }

  @media #{$sm-layout} {
    width: 30px;
  }
}

.clickhere {
  cursor: pointer;
}
.clickhere1 {
  cursor: pointer;
  color: #0000008c;
}

::-webkit-scrollbar {
  width: 0.2em;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #00adb4;
  outline: 1px solid #00adb4;
  border-radius: 20px;
}

ul::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 15px;
}

ul::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 15px;
}

::-moz-scrollbar {
  width: 0.2em;
}

::-moz-scrollbar-track {
  background: transparent;
}

::-moz-scrollbar-thumb {
  background-color: #00adb4;
  outline: 1px solid #00adb4;
  border-radius: 20px;
}

ul::-moz-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 15px;
}

ul::-moz-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 15px;
}

.profileicon-light {
  width: 35px;
  display: inline;
}

.profileicon-dark {
  width: 35px;
  display: none;
}

.profileicon-imp {
  width: 35px;
  display: none;
}

.profileicon:hover {
  .profileicon-light {
    width: 35px;
    display: none;
  }

  .profileicon-dark {
    width: 35px;
    display: none;
  }

  .profileicon-imp {
    width: 35px;
    display: inline;
  }
}

body {
  overflow-x: hidden;
}

.thumbgenbtn1-upscale {
  align-items: center;
  @media #{$sm-layout} {
    margin-top: 20px;
  }
}

.servicesddm {
  cursor: pointer;
}

.crownicon {
  width: 18px;
}

.loader {
  width: 160px;
  height: 185px;
  position: relative;
  background: #e5e8f0;
  border-radius: 100px 100px 0 0;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 125px;
  left: 50%;
  top: 25px;
  transform: translateX(-50%);
  background-image: radial-gradient(circle, #000 48%, transparent 55%),
    radial-gradient(circle, #000 48%, transparent 55%),
    radial-gradient(circle, #fff 30%, transparent 45%),
    radial-gradient(circle, #000 48%, transparent 51%),
    linear-gradient(#000 20px, transparent 0),
    linear-gradient(#f0ede5 60px, transparent 0),
    radial-gradient(circle, #f0ede5 50%, transparent 51%),
    radial-gradient(circle, #f0ede5 50%, transparent 51%);
  background-repeat: no-repeat;
  background-size: 16px 16px, 16px 16px, 10px 10px, 42px 42px, 12px 3px,
    50px 25px, 70px 70px, 70px 70px;
  background-position: 25px 10px, 55px 10px, 36px 44px, 50% 30px, 50% 85px,
    50% 50px, 50% 22px, 50% 45px;
  animation: faceLift 3s linear infinite alternate;
}

.loader:before {
  content: "";
  position: absolute;
  width: 140%;
  height: 125px;
  left: -20%;
  top: 0;
  background-image: radial-gradient(circle, #e5e8f0 48%, transparent 50%),
    radial-gradient(circle, #e5e8f0 48%, transparent 50%);
  background-repeat: no-repeat;
  background-size: 65px 65px;
  background-position: 0px 12px, 145px 12px;
  animation: earLift 3s linear infinite alternate;
}

@keyframes faceLift {
  0% {
    transform: translateX(-60%);
  }

  100% {
    transform: translateX(-30%);
  }
}

@keyframes earLift {
  0% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
}

.loadermain {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.laptoppose {
  opacity: 0;
  display: none;
}

.sliderhome {
  position: relative;
  top: 60px;
  @media #{$laptop-device} {
    top: 40px;
  }

  @media #{$smlg-device} {
    top: -130px;
  }

  @media #{$md-layout} {
    top: -20px;
  }

  @media #{$sm-layout} {
    top: -20px;
  }
}

.tabs {
  display: flex;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
}

.tabs * {
  z-index: 2;
}

.containerTabList input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  font-size: 0.8rem;
  @media #{$sm-layout} {
    font-size: 0.7rem;
  }
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  height: 40px;
}

.containerTabList input[type="radio"]:checked + label {
  color: #00adb4;
}

.containerTabList input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0%);
  @media #{$sm-layout} {
    transform: translateX(-5%);
  }
}

.containerTabList input[id="radio-2"]:checked ~ .glider {
  transform: translateX(95%);

  @media #{$smlg-device} {
    transform: translateX(90%);
  }
  @media #{$sm-layout} {
    transform: translateX(88%);
  }
}

.containerTabList input[id="radio-3"]:checked ~ .glider {
  transform: translateX(190%);

  @media #{$smlg-device} {
    transform: translateX(185%);
  }
  @media #{$md-layout} {
    transform: translateX(190%);
  }
  @media #{$sm-layout} {
    transform: translateX(180%);
  }
}
.containerTabList input[id="radio-4"]:checked ~ .glider {
  transform: translateX(285%);

  @media #{$smlg-device} {
    transform: translateX(278%);
  }
  @media #{$md-layout} {
    transform: translateX(285%);
  }
  @media #{$sm-layout} {
    transform: translateX(274%);
  }
}

.glider {
  position: absolute;
  display: flex;
  height: 40px;
  width: 25%;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.containerTabList {
  margin-bottom: 20px;
  text-align: -webkit-center;
}

//   .chat-group{
//     z-index: 3;
//   }

.featuresSection {
  margin-bottom: 30px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//   .youtube{
//     color: #f00;
//     font-weight: bolder;
//   }

.thumbnails {
  color: #00adb4;
}

.homeicon {
  width: 40px;
}

.banner-body {
  color: #e6eef9;
  margin-bottom: 18px;
}

.subPageGraphics {
  width: 70%;
}

.subPageGraphicsbgr {
  width: 65%;
  @media #{$sm-layout} {
    width: 40%;
  }
}

.subPageGraphicsupscale {
  width: 45%;
  @media #{$laptop-device} {
    width: 60%;
  }

  @media #{$smlg-device} {
    width: 90%;
  }

  @media #{$md-layout} {
    width: 80%;
  }

  @media #{$sm-layout} {
    width: 90%;
  }
}

.outer-slider {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
}
.outer-slider > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}
.outer-slider .below {
  z-index: 1;
  // box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
  //   0 6px 12px 0 rgba(23, 210, 224, 0.15);
  border-radius: 20px;
}
.outer-slider .top {
  z-index: 2;
}

.sliderloader2 {
  opacity: 1;
}

.loaderimage {
  border-radius: 20px;
}

.thumbgenbtn1lnk {
  cursor: pointer;
}

.hide-slider {
  display: none;
}

.privacy-footer {
  width: 40%;

  // @media #{$sm-layout} {
  //     width: 100%;

  // }

  @media only screen and (max-width: 767px) {
    width: 50%;
  }

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
}

.color-popover {
  position: absolute;
  z-index: 3;
  display: inline;
}

.color-cover {
  position: fixed;
  top: "0px";
  right: "0px";
  bottom: "0px";
  left: "0px";
}

.div-for-color {
  background-color: red;
  padding-left: 220px;
  padding-right: 220px;
  padding-top: 165px;
  padding-bottom: 165px;
}

.tabcusgen {
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 355px;
  background-color: transparent;
  margin-bottom: 130px;

  @media #{$laptop-device} {
    height: 355px;
  }

  @media #{$smlg-device} {
    height: 285px;
  }

  @media #{$md-layout} {
    height: 230px;
  }

  @media #{$sm-layout} {
    height: 500px;
    display: grid;
    text-align: center;
  }
}

.tabs-cusgen {
  display: flex;
  position: relative;
  background-color: #ffffff;
  // box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.4rem;
  border-radius: 99px;
  width: 95%;
  margin-left: 10px;
  // box-shadow: var(--color-dark) 0px 15px 50px -12px;
  // box-shadow: 2px 1px 114px -13px rgba(71,71,71,0.6);
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
  // -webkit-box-shadow: 2px 1px 114px -13px rgba(71,71,71,0.6);
  // -moz-box-shadow: 2px 1px 114px -13px rgba(71,71,71,0.6);
  @media #{$sm-layout} {
    margin-left: 0px;
  }
}

.tabs-cusgen * {
  z-index: 2;
}

.containerTabList-cusgen input[type="radio"] {
  display: none;
}

.tab-cusgen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  font-size: 0.8rem;
  @media #{$sm-layout} {
    font-size: 0.7rem;
  }
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
  height: 40px;
}

.containerTabList-cusgen input[type="radio"]:checked + label {
  color: #00adb4;
}

.containerTabList-cusgen input[id="radio-1-cusgen"]:checked ~ .glider-cusgen {
  transform: translateX(0%);
}

.containerTabList-cusgen input[id="radio-2-cusgen"]:checked ~ .glider-cusgen {
  transform: translateX(93%);
}

.glider-cusgen {
  position: absolute;
  display: flex;
  height: 40px;
  width: 50%;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.containerTabList-cusgen {
  // margin-bottom: 20px;
  text-align: -webkit-center;
}

.chat-typing-box {
  display: grid;
  justify-content: center;
  align-items: center;
  // padding: 10px;
  background-color: #ffffff00;
  border-radius: 10px;
  width: 373px;
  min-height: 100%;
  margin-bottom: 10px;
  box-shadow: none;
  z-index: 3;
  // -webkit-box-shadow: -1px 1px 400px 11px rgba(71,71,71,0.75);
  // -moz-box-shadow: -1px 1px 400px 11px rgba(71,71,71,0.75);

  @media #{$laptop-device} {
    width: 373px;
    min-height: 100%;
  }

  @media #{$smlg-device} {
    width: 370px;
    min-height: 100%;
  }

  @media #{$md-layout} {
    width: 310px;
    min-height: 100%;
  }

  @media #{$sm-layout} {
    width: 313px;
    min-height: 100%;
  }
}

//   .chat-typing-box input {
//     flex-grow: 1;
//     padding: 8px;
//     border: none;
//     border-radius: 10px;
//     font-size: 16px;
//     margin-right: 10px;

//   }

.chat-typing-box button {
  padding: 8px 16px;
  margin-bottom: 10px;
  border: none;
  border-radius: 10px;
  background-color: #00adb4;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.chat-typing-box button:hover {
  background-color: var(--color-primary2);
}
.chat-typing-box textarea {
  flex-grow: 1;
  padding: 15px;
  border: 1px solid #11111125;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none; /* Disable textarea resizing */
  overflow-y: auto; /* Hide vertical scrollbar */
  height: 270px;
  width: 345px;
  // box-shadow: var(--color-dark) 0px 15px 50px -12px;
  @media #{$smlg-device} {
    height: 200px;
    width: 345px;
  }
  @media #{$md-layout} {
    height: 150px;
    width: 300px;
  }
  @media #{$sm-layout} {
    width: 300px;
    min-height: 220px;
  }
}
.chat-typing-box img {
  flex-grow: 1;
  padding: 15px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 270px;
  width: 345px;
  @media #{$smlg-device} {
    height: 200px;
    width: 345px;
  }
  @media #{$md-layout} {
    height: 150px;
    width: 300px;
  }
  @media #{$sm-layout} {
    width: 300px;
    min-height: 220px;
  }
}

.chat-typing-box textarea::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 15px;
}

.chat-typing-box textarea::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 15px;
}

.ul-placeholder {
  opacity: 0;
}
.ul-loader {
  flex-grow: 1;
  padding: 15px;
  border: 1px solid #11111125;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  resize: none; /* Disable textarea resizing */
  overflow-y: auto; /* Hide vertical scrollbar */
  height: 270px;
  width: 345px;
  // box-shadow: var(--color-dark) 0px 15px 50px -12px;
  @media #{$smlg-device} {
    height: 200px;
    width: 345px;
  }
  @media #{$md-layout} {
    height: 150px;
    width: 300px;
  }
  @media #{$sm-layout} {
    width: 300px;
    min-height: 220px;
  }
}

.change-cursor {
  cursor: pointer;
}

.logold {
  margin-left: 24px;
  @media #{$smlg-device} {
    margin-left: 24px;
  }
  @media #{$md-layout} {
    margin-left: 0px;
  }
  @media #{$sm-layout} {
    margin-left: 0px;
  }
}

.col-md-8 {
  flex: 0 0 auto;
  width: 50%;
}

.profileicon-dark {
  border-radius: 50%;
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
}
.profileicon-light {
  border-radius: 50%;
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
}

.loader-bg-gen {
  width: 5em;
  height: 5em;
  background: linear-gradient(-45deg, #00b461 0%, #00adb4 100%);
  animation: spin 3s infinite;
}

.loader-bg-gen::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #00b461 0%, #00adb4 100%);
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

@keyframes spin {
  0% {
    transform: rotate(-45deg);
    border-radius: 10%;
  }

  50% {
    transform: rotate(-360deg);
    border-radius: 50%;
  }

  100% {
    transform: rotate(-45deg);
    border-radius: 10%;
  }
}

.cancelbtn {
  width: 100px;
  border: var(--border-gray);
  margin-bottom: 20px;

  @media #{$sm-layout} {
    width: 150px;
  }
}

.confirmbtn {
  width: 150px;
  background: linear-gradient(-45deg, #00b461 0%, #00adb4, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  // height: 100vh;
  animation: gradient 10s ease infinite;
  margin-bottom: 20px;

  &:hover {
    &::after {
      transform: translateY(-50%) translateX(-50%) scale(1);
    }
  }
}

.boldtext {
  // background-image:  radial-gradient(circle,  #aed0d0,#2ac8ce);
  background: linear-gradient(-45deg, #00b461 0%, #00adb4, #23a6d5, #23d5ab);
  //  background: linear-gradient(-45deg, #23a6d5, #e72416, #ee7752, #e73c7e);

  background-size: 400% 400%;
  // height: 100vh;
  animation: gradient 10s ease infinite;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.dollaricon {
  width: 22px;
  margin-bottom: 2px;
}
.crediticon {
  margin: 0 24px;

  @media #{$smlg-device} {
    margin: 0 24px;
  }

  > a {
    color: var(--color-body);
    font-weight: 500;
    font-size: var(--font-body-2);
    font-family: var(--font-primary);
    line-height: 60px;
    height: 60px;
    display: block;
    position: relative;
    transition: var(--transition);

    &::before {
      content: "";
      height: 2px;
      width: 0;
      background-color: var(--color-primary);
      position: absolute;
      bottom: 12px;
      left: 0;
      opacity: 0;
      transition: 0.5s;
    }

    &:hover {
      color: var(--color-primary);

      &::before {
        opacity: 0;
        width: 100%;
      }
    }

    &.active {
      color: var(--color-primary);

      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }
}
.profile-box {
  text-align: -webkit-center;
  text-align: -moz-center;
}

.accounticon {
  width: 20px;
}

.profile-avatar {
  margin-bottom: 30px;
  align-items: center;
}

.profile-avatar-mui {
  background: linear-gradient(-45deg, #00b461 0%, #00adb4, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}
.profile-credit {
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.profile-credit-icon {
  width: 35px;
}

.profile-credit-display {
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.header .profile-email {
  color: #525260;
  font-size: 25px;
  font-weight: 500;
  width: 100%;
  @media #{$md-layout} {
    font-size: 20px;
  }

  @media #{$sm-layout} {
    font-size: 18px;
  }
}

.profile-text {
  color: #525260;
  font-size: 15px;
}

.row-signin .profile-body {
  padding-bottom: 60px;
  @media #{$laptop-device} {
    padding: 125px 0;
  }

  @media #{$smlg-device} {
    padding: 120px 0;
  }

  @media #{$md-layout} {
    padding: 110px 0;
  }

  @media #{$sm-layout} {
    padding: 60px 0;
  }
}

.creditloading {
  width: 20px;
}

.credits-header-mobile {
  color: #525260;
  &:hover {
    color: #00adb4;
  }
}
.credits-hover {
  display: none;
  opacity: 0;
  transform: scale(0.9);
}

.crediticon:hover .credits-hover {
  display: inline;
  background: linear-gradient(-45deg, #00b461 0%, #00adb4, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: expand 1s forwards;
}

@keyframes expand {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.crediticon:not(:hover) .credits-hover {
  animation: contract 0.3s forwards;
}

@keyframes contract {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.code-box-demo .demo {
  overflow: auto;
}

.ant-popover .ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
  max-width: 250px;
  max-height: 80px;
}

.credit-number {
  color: #525260;
  &:hover {
    color: #00adb4;
  }
}

.home-page-gif {
  border-radius: 20px;
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
  // height: 100%;
}

.upload-text-sub {
  font-size: 12px;
  font-weight: 350;
}

.show-cost {
  background-color: #ffffff;
  color: #343333b0;
  border-radius: 15px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}
.show-cost-highligted {
  background: linear-gradient(-45deg, #aedec9 0%, #9cdcdd, #46bbe2, #60e2bde7);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  color: #434141ee;
  border-radius: 15px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s; /* Add transition properties */
  box-shadow: 0 0 30px 0 rgba(0, 120, 156, 0.442),
    0 6px 12px 0 rgba(0, 156, 167, 0.455);
  transition: box-shadow 0.3s, color 0.3s; /* Add transition properties */
}

.banner-popover-text {
  margin-bottom: 0;
}
.phone-number a {
  padding-bottom: 10px;
  font-size: 80%;
}

//payment

// .payment-buuton-div {
//   display: flex;
//   justify-content: center;
//   margin-top: 10px;
// }

.get-credits-btn {
  width: 250px !important;

  @media #{$smlg-device} {
    width: 200px !important;
  }
  min-height: 20px;
  padding: 10px !important;
  box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
    0 6px 12px 0 rgba(23, 210, 224, 0.15);
}
