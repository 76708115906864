/*-------------------------
    Dark Style
-------------------------*/

.light-mode {
  display: block;
}

.dark-mode1 {
  display: none;
}

.faces-val-score {
  width: 90%;
  border-radius: 24px;
  background: #27272e;
  margin-top: -70px;
  // box-shadow:  12px 12px 24px #23232a,
  //              -12px -12px 24px #2b2b32;
}

@media (prefers-reduced-motion: no-preference) {
  :root .active-dark-mode {
    background-color: #212121;
  }
}

.active-dark-mode {
  color-scheme: dark;

  --dark-main-body: #212121;
  --dark-bg-color: var(--color-dark);
  --dark-body-text: #d3d3d4;
  --dark-title-light: #d3d3d4;
  --dark-solid-btn: var(--color-ghost);
  --dark-border-gray: var(--color-body);

  body {
    color: var(--dark-body-text);
  }

  p {
    color: var(--dark-body-text);
  }

  // .iconlight{
  //     // display: none;
  //     // font-size: 5px;
  //     // display: inline;
  //     width: 1%;
  //     // height: 100%;
  //     color: #fff;
  //     vertical-align: middle;
  //     margin-top: 3%;

  //   }

  .profileicon-light {
    width: 35px;
    display: none;
  }

  .profileicon-dark {
    width: 35px;
    display: inline;
  }

  .profileicon-imp {
    width: 35px;
    display: none;
  }

  .selected-image {
    box-shadow: #000000 0px 25px 50px -12px;
  }

  .image-list {
    box-shadow: #000000 0px 25px 50px -12px;
    background-color: #373636;
  }

  .mobilemenu-popup .mobilemenu-header .mobile-menu-close {
    background-color: transparent;
    height: 30px;
    width: 30px;
    border: 2px solid var(--color-gray-4);
    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    transition: var(--transition);
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  .mob {
    background-color: var(--color-dark);
  }

  .dark-mode1 {
    display: block;
  }

  .light-mode {
    display: none;
  }

  .icondark {
    // font-size: 5px;
    display: none;
    width: 10%;
    // height: 100%;
    color: #fff;
    vertical-align: middle;
    margin-top: 3%;
  }

  .icondark2 {
    // font-size: 5px;
    display: none;

    // height: 100%;
    color: #fff;
    vertical-align: middle;
    margin-top: 3%;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-upload-wrapper
    .ant-upload-drag
    .ant-upload-btn
    :hover {
    transition-delay: 5s;

    .icondark2 {
      display: inline;
    }

    .icondark {
      display: none;
    }

    .iconlight {
      display: none;
    }
  }

  .upload-text {
    font-size: auto;
    color: #27272e;
    font-weight: 500;
    font-size: 20px;
  }

  //       //css for drag and drop
  //       :where(.css-dev-only-do-not-override-1n7nwfa).ant-upload-wrapper .ant-upload-drag {
  //     border: 1px dashed #fff;
  //     position: relative;
  //     // border-style: hidden;
  //     // transition: border-color 0.3s;
  //     text-align: center;
  //     height: 200px;
  //     width: 100%;
  //     background-color: #F5F5F7;
  //     box-shadow:  black 0px 25px 50px -12px;    //box-shadow: 0 44px 84px -24px var(--color-primary2);
  //     // margin-bottom: 1%;
  //     border-radius: 30px;
  //     font-weight:400;

  //     // display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;

  //     text-align: center;
  //     transition: var(--transition);
  //     position: relative;
  //     z-index: 1;
  //     overflow: hidden;
  //     // &.btn-borderd {
  //     //     // border: var(--border-gray);
  //     //     color: var(--color-dark);
  //     //     // background-color: transparent;
  //     //     &:hover {
  //     //         background-color: var(--color-primary);
  //     //         color: var(--color-white);
  //     //         border-color: var(--color-dark);
  //     //     }
  //     // }

  //     // background-color: var(--color-white);
  //     // color: var(--color-white);
  //     // border: 2px solid var(--color-dark);
  //     // overflow: hidden;

  //     &::after {
  //         content: "";
  //         height: 1000px;
  //         width: 1000px;
  //         background-color: var(--color-dark);
  //         border-radius: 50%;
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translateY(-50%) translateX(-50%) scale(0);
  //         transition: 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  //         z-index: -1;
  //     }
  //     &:hover {
  //         // background-color: var(--color-link);
  //         border-color: var(--color-dark);
  //         &::after {
  //             transform: translateY(-50%) translateX(-50%) scale(1);
  //         }
  //     }

  //     // &.light {
  //     //     display: none;
  //     // }
  //     // &.dark {
  //     //     display: block;
  //     // }

  //   }

  //css for drag and drop
  :where(.css-dev-only-do-not-override-1n7nwfa).ant-upload-wrapper
    .ant-upload-drag {
    // border: 1px dashed #fff;
    position: relative;
    max-width: 100%;
    border-style: hidden;
    transition: border-color 0.3s;
    text-align: center;
    height: 200px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    // box-shadow: var(--color-dark) 0px 25px 50px -12px;
    background-color: #ffffff;
    box-shadow: black 0px 25px 50px -12px;
    // box-shadow: 0 44px 84px -24px var(--color-dark);
    // margin-bottom: 5%;
    border-radius: 30px;
    font-weight: 400;
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    transition: var(--transition);
    position: relative;
    z-index: 1;
    overflow: hidden;
    // &.btn-borderd {
    //     border: var(--border-gray);
    //     color: var(--color-dark);
    //     background-color: transparent;
    //     &:hover {
    //         background-color: var(--color-primary);
    //         color: var(--color-white);
    //         border-color: var(--color-primary);

    //     }
    // }

    // background-color: var(--color-white);
    // color: var(--color-white);
    // border: 1px solid var(--color-primary);
    // overflow: hidden;

    &::after {
      content: "";
      height: 1000px;
      width: 1000px;
      background-color: var(--color-dark);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0);
      transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: -1;
    }

    &:hover {
      background-color: var(--color-dark);
      // border-color: var(--color-link);

      &::after {
        transform: translateY(-50%) translateX(-50%) scale(1);
      }
    }

    // &.light {
    //     display: none;
    // }
    // &.dark {
    //     display: block;
    // }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--dark-title-light);
  }

  ul,
  ol {
    li {
      color: var(--dark-body-text);
    }
  }

  .form-group label {
    color: var(--dark-body-text);
  }

  input,
  .form-control {
    color: var(--dark-body-text);
    border-color: var(--dark-border-gray);

    &:focus {
      box-shadow: none;
      border-color: transparent;
      background-color: #171717;
    }

    /* -- Placeholder -- */
    &::placeholder {
      color: var(--dark-body-text);
      /* Firefox */
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--dark-body-text);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--dark-body-text);
    }
  }

  .input-group {
    background-color: #171717;
    box-shadow: none;

    .mail-icon {
      svg {
        fill: var(--color-white);
      }
    }

    @media #{$small-mobile} {
      background-color: transparent;

      .form-control {
        &:focus {
          background-color: transparent;
          border-color: var(--dark-border-gray);
        }
      }
    }
  }

  // Form Action Message

  .error-msg,
  .success-msg {
    margin-top: 25px;

    p {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .error-msg {
    p {
      color: #ff0000;
    }
  }

  .success-msg {
    p {
      color: #15c54b;
    }
  }

  .bg-color-light {
    background-color: #212121;
  }

  .bg-color-mercury {
    background-color: var(--dark-bg-color);
  }

  .main-wrapper {
    background-color: #212121;
  }

  // Button
  .axil-btn {
    &.btn-borderd {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      color: var(--color-white);
      overflow: hidden;

      &::after {
        content: "";
        height: 350px;
        width: 350px;
        background-color: var(--color-link);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0);
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        z-index: -1;
      }

      &:hover {
        border-color: var(--color-link);

        &:after {
          transform: translateY(-50%) translateX(-50%) scale(1);
        }
      }
    }

    &.btn-fill-white {
      background-color: var(--color-primary);
      color: var(--color-white);
      overflow: hidden;

      &::after {
        content: "";
        height: 350px;
        width: 350px;
        background-color: var(--color-link);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0);
        transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        z-index: -1;
      }

      &:hover {
        &:after {
          transform: translateY(-50%) translateX(-50%) scale(1);
          overflow: hidden;
        }
      }
    }
  }

  // Shape Image

  .shape-group-1 {
    .shape {
      img {
        filter: brightness(0.2);
      }
    }
  }

  .shape-group-3 {
    .shape:not(.shape-3) {
      img {
        filter: brightness(0.3);
      }
    }
  }

  .shape-group-4 {
    .shape-1 {
      img {
        filter: brightness(0.2);
      }
    }
  }

  .shape-group-6 {
    .shape {
      img {
        filter: brightness(0.3);
      }
    }
  }

  .shape-group-7 {
    .shape {
      img {
        filter: brightness(0.2);
      }
    }
  }

  .shape-group-8 {
    .shape-1,
    .shape-2,
    .shape-3 {
      img {
        filter: brightness(0.3);
      }
    }
  }

  .shape-group-9 {
    .shape {
      img {
        filter: brightness(0.5);
      }
    }
  }

  .shape-group-11 {
    .shape {
      img {
        filter: brightness(0.5);
      }
    }
  }

  .shape-group-12 {
    .shape-3 {
      img {
        opacity: 0.3;
      }
    }
  }

  .shape-group-13 {
    .shape-1 {
      img {
        filter: brightness(0.1);
      }
    }

    .shape-2 {
      img {
        filter: brightness(0.3);
      }
    }
  }

  .shape-group-17 {
    .shape {
      img {
        filter: brightness(0.3);
      }
    }
  }

  .shape-group-19 {
    .shape {
      opacity: 0.2;
    }
  }

  .shape-group-20 {
    .shape-1 {
      opacity: 0.2;
    }

    .shape-2 {
      opacity: 0.2;
    }
  }

  .shape-group-21 {
    .shape-1 {
      img {
        filter: brightness(0.1);
      }
    }

    .shape-2 {
      img {
        filter: brightness(0.3);
      }
    }
  }

  // Slick
  .slick-dot-nav {
    .slick-dots {
      li {
        button {
          background-color: var(--dark-body-text);
        }

        &.slick-active {
          button {
            background-color: var(--color-primary);
          }
        }
      }
    }
  }

  // Header
  .mainmenu {
    li {
      a {
        color: var(--dark-body-text);

        &::before {
          background-color: var(--dark-body-text);
        }

        &:hover {
          color: var(--color-primary);
        }
      }
    }

    > .itemsnav {
      > a {
        &::before {
          content: "";
          height: 2px;
          width: 0;
          background-color: var(--color-primary);
          position: absolute;
          bottom: 12px;
          left: 0;
          opacity: 0;
          transition: 0.5s;
        }

        &:hover {
          color: var(--color-primary);

          &::before {
            opacity: 1;
            width: 100%;
          }
        }

        &.active {
          color: var(--color-primary);

          &::before {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }

    .menu-item-has-children {
      a {
        &::after {
          color: var(--dark-body-text);
        }
      }

      .axil-submenu {
        background-color: var(--dark-bg-color);

        li {
          a {
            color: var(--dark-body-text);

            &::after {
              background-color: var(--dark-title-light);
            }

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }

      .axil-submenu-loggedin {
        background-color: var(--dark-bg-color);

        li {
          a {
            color: var(--dark-body-text);

            &::after {
              background-color: var(--dark-title-light);
            }

            &:hover {
              color: var(--color-primary);
            }
          }
        }
      }
    }
  }

  .axil-mainmenu.axil-sticky {
    background-color: var(--dark-bg-color);

    .header-action {
      .social-icon-list {
        li {
          a {
            color: var(--color-white);
          }
        }
      }
    }
  }

  .axil-header {
    .header-navbar {
      .header-logo {
        .light-version-logo {
          display: none;
        }

        .dark-version-logo {
          display: block;
        }
      }
    }

    &.header-style-1 {
      .axil-mainmenu {
        &.axil-sticky {
          .header-navbar {
            .header-logo {
              .sticky-logo {
                display: none;
              }
            }
          }
        }
      }
    }

    &.header-style-3 {
      .axil-mainmenu {
        &.axil-sticky {
          .header-navbar {
            .header-logo {
              .sticky-logo {
                display: none;
              }
            }
          }
        }
      }

      .header-action {
        .sidemenu-btn {
          .btn-wrap {
            background-color: #464646;
          }
        }

        .social-icon-list {
          li {
            a {
              color: var(--dark-body-text);
            }
          }
        }
      }
    }

    &.header-style-4 {
      .mainmenu-nav {
        .mainmenu {
          li {
            a {
              color: var(--dark-body-text);

              &::after {
                color: var(--dark-body-text);
              }
            }
          }

          .menu-item-has-children {
            .axil-submenu {
              li {
                a {
                  color: var(--dark-body-text);
                }
              }
            }
          }
        }
      }
    }
  }

  .header-offcanvasmenu {
    background-color: var(--dark-bg-color);

    .offcanvas-header {
      .btn-close {
        background-color: var(--dark-body-text);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 14px;
      }
    }

    .offcanvas-body {
      .main-navigation {
        li {
          a {
            color: var(--dark-title-light);

            &:hover {
              color: var(--color-white);
            }
          }
        }
      }

      .contact-inner {
        .title {
          color: var(--dark-title-light);
        }

        .address {
          a {
            color: var(--dark-body-text);

            &:hover {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }

  .header-action {
    .sidemenu-btn {
      .btn-wrap {
        background-color: var(--color-body);

        span {
          background-color: var(--dark-title-light);
        }
      }
    }
  }

  .mobile-nav-header {
    border-bottom-color: var(--dark-border-gray);

    .mobile-menu-close {
      background-color: var(--color-white);
      color: var(--dark-bg-color);
      border: none;
    }

    .mobile-nav-logo {
      .light-mode {
        display: none;
      }

      .dark-mode {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .mainmenu-nav {
      background-color: var(--dark-bg-color);

      .mainmenu {
        > li {
          border-bottom-color: var(--dark-border-gray);
        }

        .menu-item-has-children {
          .axil-submenu {
            background-color: transparent;

            li {
              a {
                color: var(--dark-body-text);

                &:hover {
                  color: var(--color-primary);
                }
              }
            }
          }
        }
      }
    }
  }

  // Banner
  .banner {
    .banner-content {
      .title {
        // color: var(--dark-title-light);
        // background-image:  radial-gradient(circle, #00adb4, #fff);
        background: linear-gradient(
          -45deg,
          #00b461 0%,
          #00adb4,
          #23a6d5,
          #23d5ab
        );
        background-size: 400% 400%;
        // height: 100vh;
        animation: gradient 10s ease infinite;

        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }

      .youtube {
        // background-image:  radial-gradient(circle, rgb(232, 51, 51), rgba(255, 255, 255, 0.835));
        background: linear-gradient(-45deg, #ee7752, #e72416, #ee7752, #e73c7e);
        background-size: 400% 400%;
        // height: 100vh;
        animation: gradient 10s ease infinite;
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }

      .subtitle {
        color: var(--dark-body-text);
      }

      .axil-btn {
        box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
          0 6px 12px 0 rgba(23, 210, 224, 0.15);
      }
    }
    @keyframes gradient {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }

    .banner-social {
      .social-icon {
        li {
          a {
            color: var(--dark-body-text);

            i {
              color: var(--dark-body-text);
            }
          }
        }
      }
    }

    &.banner-style-2 {
      background-color: var(--dark-bg-color);

      .title {
        // color: var(--dark-title-light);
        // background-image:  radial-gradient(circle, #00adb4, #fff);
        background: linear-gradient(
          -45deg,
          #00b461 0%,
          #00adb4,
          #23a6d5,
          #23d5ab
        );
        background-size: 400% 400%;
        // height: 100vh;
        animation: gradient 10s ease infinite;

        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }
    }

    &.banner-style-4 {
      background: var(--dark-main-body);
    }

    &.banner-style-5 {
      .banner-content {
        .axil-btn {
          color: var(--dark-solid-btn);
        }
      }
    }
  }

  // Section Heading
  .section-heading {
    .title {
      color: var(--dark-title-light);
    }

    p {
      color: var(--dark-body-text);
    }
  }

  .heading-light-left {
    p {
      opacity: 1 !important;
    }
  }

  // Breadcrumb
  .breadcrum-area {
    background: #212121;
  }

  // Case Study
  .case-study-counterup {
    .single-counterup {
      border-right-color: var(--dark-border-gray);
    }

    .counter-title {
      color: var(--dark-body-text);
    }
  }

  // Project

  .isotope-project-btn {
    button {
      color: var(--dark-body-text);

      &::after {
        background-color: var(--dark-body-text);
      }
    }
  }

  .project-add-banner {
    .content {
      .title {
        color: var(--color-dark);
      }
    }
  }

  .project-grid {
    box-shadow: none;

    .content {
      background-color: var(--dark-bg-color);

      .subtitle {
        color: var(--dark-body-text);
      }
    }

    &.project-style-2 {
      .content {
        background-color: transparent;

        .subtitle {
          color: var(--color-body);
        }

        .title {
          color: var(--color-text-dark);
        }
      }
    }
  }

  // Process
  .process-work {
    .content {
      p {
        color: var(--dark-body-text);
      }
    }
  }

  // Service
  .services-grid {
    .content {
      .title {
        a {
          color: inherit;

          &:hover {
            color: var(--color-primary);
          }
        }
      }

      p {
        color: var(--dark-body-text);
        opacity: 1;
      }

      .more-btn {
        color: var(--dark-solid-btn);
      }
    }

    &:hover,
    &.active {
      .content {
        .more-btn {
          color: var(--color-accent2);
        }
      }
    }

    &.service-style-2 {
      background: var(--dark-bg-color);

      .content {
        .title {
          a {
            color: var(--dark-title-light);
          }
        }

        p {
          color: var(--dark-body-text);
        }

        .more-btn {
          color: var(--dark-solid-btn);
        }
      }

      &:hover,
      &.active {
        .content {
          .more-btn {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .service-scroll-nav {
    background-color: var(--dark-main-body);

    ul {
      border-bottom-color: var(--dark-border-gray);

      li {
        a {
          color: var(--dark-body-text);

          &:hover {
            color: var(--color-white);
          }

          &:after {
            background-color: var(--color-white);
          }
        }

        &.current {
          a {
            color: var(--color-white);
          }
        }
      }
    }
  }

  // Contact
  .contact-form-box {
    background-color: var(--dark-main-body);

    &.shadow-box {
      box-shadow: 0 20px 48px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .contact-address {
    .address {
      p {
        color: var(--dark-body-text);

        a {
          color: var(--dark-body-text);
        }
      }
    }
  }

  // Testimonial
  .testimonial-grid {
    .author-info {
      .content {
        .name {
          color: var(--dark-title-light);
        }
      }
    }

    p {
      color: var(--dark-body-text);
    }
  }

  .followers-list {
    li {
      a {
        span {
          color: var(--dark-body-text);
        }
      }
    }
  }

  // Pricing Table
  .pricing-billing-duration {
    ul {
      background-color: var(--dark-main-body);
      border-color: var(--dark-border-gray);

      .nav-link {
        color: var(--dark-body-text);

        &.active {
          background-color: var(--color-link);
        }
      }
    }
  }

  .pricing-table {
    background-color: var(--dark-main-body);

    .pricing-header {
      .subtitle {
        color: var(--dark-body-text);
      }

      .price-wrap {
        border-color: rgba(227, 230, 233, 0.2);

        .amount {
          color: var(--dark-body-text);
        }
      }
    }

    &:hover,
    &.active {
      .pricing-header {
        .price-wrap {
          border-color: rgba(227, 230, 233, 0.4);

          .amount {
            color: var(--color-white);
          }
        }

        .axil-btn {
          background-color: var(--color-primary);
          border-color: var(--color-link);
          color: var(--color-white);

          &:after {
            transform: translateY(-50%) translateX(-50%) scale(1);
          }
        }
      }
    }

    &.pricing-borderd {
      border-color: var(--dark-border-gray);
    }
  }

  // Why Choose
  .why-choose-us {
    .accordion-item {
      border-bottom-color: var(--dark-border-gray);

      .accordion-button {
        &.collapsed {
          color: var(--dark-body-text);
        }
      }

      .accordion-body {
        color: var(--dark-body-text);
      }
    }
  }

  // FAQ
  .faq-accordion {
    .accordion-item {
      background-color: var(--dark-main-body);
      box-shadow: none;
    }

    .accordion-button {
      background-color: transparent;

      &.collapsed {
        color: var(--dark-body-text);

        &::after {
          color: var(--dark-body-text);
        }
      }
    }

    .accordion-body {
      p {
        color: var(--dark-body-text);
      }
    }
  }

  // Counter Up
  .counterup-progress {
    .title {
      color: var(--dark-title-light);
    }

    &.counterup-style-2 {
      background: var(--dark-bg-color);

      .icon {
        .light-icon {
          display: inline-block;
        }

        .dark-icon {
          display: none;
        }
      }

      .title {
        color: var(--dark-title-light);
      }
    }
  }

  // Blog
  .blog-list {
    border-top-color: var(--dark-border-gray);
    border-bottom-color: var(--dark-border-gray);

    .post-content {
      p {
        color: var(--dark-body-text);
      }

      .more-btn {
        color: var(--dark-solid-btn);
      }
    }

    &.border-start {
      border-left-color: var(--dark-border-gray) !important;
    }
  }

  .blog-grid {
    .title {
      color: var(--dark-title-light);
    }

    &.blog-without-thumb {
      background: var(--dark-bg-color);
    }
  }

  .blog-author {
    background-color: var(--dark-bg-color);

    .author {
      .info {
        .title {
          color: var(--dark-title-light);
        }

        p {
          color: var(--dark-body-text);
        }
      }

      .social-share {
        li {
          a {
            color: var(--dark-body-text);

            &:hover {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }

  .blog-comment-form {
    background-color: var(--dark-bg-color);
    box-shadow: none;

    .form-control {
      &:focus {
        background-color: var(--dark-main-body);
      }
    }
  }

  .single-blog {
    .single-blog-content {
      .wp-block-quote {
        background: var(--dark-bg-color);
      }
    }
  }

  .wp-block-quote {
    background: var(--dark-bg-color);
  }

  // Call To Action
  .call-to-action-area {
    border: var(--dark-border-gray);

    &::before {
      background-color: #212121;
    }

    .call-to-action {
      border-color: var(--dark-border-gray);
    }
  }

  // Footer
  .footer-social-link {
    ul {
      li {
        a {
          color: var(--dark-body-text);
          transition: var(--transition);

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }
  }

  .footer-widget {
    &.border-end {
      border-right-color: var(--dark-border-gray) !important;
    }
  }

  .footer-menu-link {
    li {
      a {
        color: var(--dark-body-text);

        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }

  .footer-bottom {
    border-top-color: var(--dark-border-gray);
  }

  .footer-bottom-link {
    ul {
      li {
        a {
          color: var(--dark-body-text);

          &:hover {
            color: var(--color-white);
          }
        }

        &::after {
          background-color: var(--dark-body-text);
        }
      }
    }
  }

  .footer-copyright {
    .copyright-text {
      color: var(--dark-body-text);

      a {
        color: var(--dark-body-text);

        &:hover {
          color: var(--color-white);
        }
      }
    }
  }

  // Widget
  .widget {
    &.widget-search {
      .blog-search {
        input {
          /* -- Placeholder -- */
          &::placeholder {
            color: var(--dark-body-text);
            /* Firefox */
            opacity: 1;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: var(--dark-body-text);
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: var(--dark-body-text);
          }
        }

        .search-button {
          color: var(--dark-body-text);
        }
      }
    }

    &.widget-categories {
      .category-list {
        a {
          color: var(--dark-body-text);
          border-bottom-color: var(--dark-border-gray);

          &::after {
            border-color: transparent transparent transparent
              var(--dark-body-text);
          }
        }
      }
    }

    &.widge-social-share {
      .blog-share {
        background-color: var(--dark-bg-color);
      }
    }

    &.widget-recent-post {
      .single-post {
        border-bottom-color: var(--dark-border-gray);
      }
    }
  }

  // Pagination

  .pagination {
    li {
      a {
        border-color: var(--dark-border-gray);
        color: var(--dark-body-text);

        &:hover {
          background-color: var(--color-primary);
          border-color: var(--color-primary);
        }

        &.current {
          border-color: var(--color-primary);
        }
      }
    }
  }

  // Error Page
  .onepage-screen-area {
    background: transparent;

    .content {
      .countdown {
        .countdown-section {
          &::after {
            background-color: var(--dark-border-gray);
          }

          .countdown-unit {
            color: var(--dark-body-text);
          }
        }
      }
    }

    &.coming-soon-area {
      .site-logo {
        .logo-light {
          display: none;
        }

        .logo-dark {
          display: block;
        }
      }
    }
  }

  // Splash Page

  .splash-main-banner {
    background-color: var(--color-text-dark);

    &:before {
      background: var(--dark-main-body);
    }

    .site-element-count {
      .count-title {
        color: var(--dark-body-text);
      }
    }

    .demo-slider {
      .slick-slide {
        a {
          box-shadow: none;
        }
      }
    }
  }

  .main-demo-area {
    .single-demo {
      .thumb {
        box-shadow: none;
      }
    }

    .shape-group {
      li {
        opacity: 0.1;
      }
    }
  }

  .spalsh-why-choose {
    .why-buy-box {
      background-color: var(--dark-main-body);
      border-color: var(--dark-main-body);

      &.active {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
      }
    }

    .line-shape {
      opacity: 0.1;
    }
  }

  .support-box {
    a {
      background-color: var(--dark-main-body);
      border-color: var(--dark-main-body);
    }

    &:hover,
    &.active {
      a {
        box-shadow: 0 50px 50px -24px rgba(1, 1, 1, 0.3);
      }
    }
  }

  .splash-call-to-action {
    background-color: var(--dark-bg-color);
  }

  .input {
    position: relative;
    margin-bottom: 24px;
    width: 100%;
  }

  .input input {
    width: 100%;
    border: none;
    padding: 4px 40px;
    border-radius: 20px;
    background-color: #27272e;
    color: #f3f4f6;
    font-size: 16px;
    height: 50px;
  }

  .input input::placeholder {
    color: #f3f4f68e;
  }

  .tabs {
    display: flex;
    position: relative;
    background-color: #212529;
    box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
      0 6px 12px 0 rgba(23, 210, 224, 0.15);
    padding: 0.75rem;
    border-radius: 99px;
  }

  .tabs * {
    z-index: 2;
  }

  .containerTabList input[type="radio"] {
    display: none;
  }

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;
    font-size: 0.8rem;
    @media #{$sm-layout} {
      font-size: 0.7rem;
    }
    color: rgb(200, 198, 198);
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    height: 40px;
  }

  .containerTabList input[type="radio"]:checked + label {
    color: #00adb4;
  }

  .containerTabList input[id="radio-1"]:checked ~ .glider {
    transform: translateX(0%);
    @media #{$sm-layout} {
      transform: translateX(-5%);
    }
  }

  .containerTabList input[id="radio-2"]:checked ~ .glider {
    transform: translateX(95%);

    @media #{$smlg-device} {
      transform: translateX(90%);
    }
    @media #{$sm-layout} {
      transform: translateX(88%);
    }
  }

  .containerTabList input[id="radio-3"]:checked ~ .glider {
    transform: translateX(190%);

    @media #{$smlg-device} {
      transform: translateX(185%);
    }
    @media #{$md-layout} {
      transform: translateX(190%);
    }
    @media #{$sm-layout} {
      transform: translateX(180%);
    }
  }
  .containerTabList input[id="radio-4"]:checked ~ .glider {
    transform: translateX(285%);

    @media #{$smlg-device} {
      transform: translateX(278%);
    }
    @media #{$md-layout} {
      transform: translateX(285%);
    }
    @media #{$sm-layout} {
      transform: translateX(274%);
    }
  }

  .glider {
    position: absolute;
    display: flex;
    height: 40px;
    width: 25%;
    background-color: #0b0e12;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
  }

  .containerTabList {
    margin-bottom: 20px;
    text-align: -webkit-center;
  }
  .tabs-cusgen {
    display: flex;
    position: relative;
    background-color: #212529;
    // box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    // box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
    padding: 0.4rem;
    border-radius: 99px;
    width: 95%;
    margin-left: 10px;
    // box-shadow: #171717 0px 15px 50px -12px;
    box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
      0 6px 12px 0 rgba(23, 210, 224, 0.15);

    @media #{$sm-layout} {
      margin-left: 0px;
    }
  }

  .tabs-cusgen * {
    z-index: 2;
  }

  .containerTabList-cusgen input[type="radio"] {
    display: none;
  }

  .tab-cusgen {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 100%;
    font-size: 0.8rem;
    @media #{$sm-layout} {
      font-size: 0.7rem;
    }
    color: rgb(200, 198, 198);
    font-weight: 500;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
    height: 40px;
  }

  .containerTabList-cusgen input[type="radio"]:checked + label {
    color: #00adb4;
  }

  .containerTabList-cusgen input[id="radio-1-cusgen"]:checked ~ .glider-cusgen {
    transform: translateX(0%);
  }

  .containerTabList-cusgen input[id="radio-2-cusgen"]:checked ~ .glider-cusgen {
    transform: translateX(93%);
  }

  .glider-cusgen {
    position: absolute;
    display: flex;
    height: 40px;
    width: 50%;
    background-color: #0b0e12;
    z-index: 1;
    border-radius: 99px;
    transition: 0.25s ease-out;
  }

  .containerTabList-cusgen {
    // margin-bottom: 20px;
    text-align: -webkit-center;
  }

  // Color Switcher
  .my_switcher {
    background-color: var(--color-white);

    @media #{$md-layout} {
      position: initial;
      display: none;
    }

    button {
      background-color: transparent;

      span {
        color: var(--color-dark);
        background-color: transparent;

        &.dark {
          display: none;
        }
      }
    }
  }

  //profile page css

  .header .profile-email {
    color: #d3d3d4;
  }

  .credits-header-mobile {
    color: #e5e8f0;
    &:hover {
      color: #00adb4;
    }
  }

  // .credit-notice-text{
  //     color: #101011;
  // }
  .ant-popover .ant-popover-inner {
    background-color: var(--color-dark);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
  .ant-popover-arrow {
    --antd-arrow-background-color: var(--color-dark);
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .ant-popover-title {
    color: var(--color-white);
  }

  .upload-text-sub {
    font-size: 12px;
    font-weight: 350;
  }

  .show-cost {
    background-color: #000;
    color: #ffffffdc;
    // transition: background-color 0.3s, color 0.3s; /* Add transition properties */
  }

  .show-cost-highligted {
    background: linear-gradient(-45deg, #069954 0%, #033536, #49717f, #345149);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    color: #ffffffdc;
    transition: background-color 0.3s, color 0.3s; /* Add transition properties */
    box-shadow: 0 0 30px 0 rgba(69, 196, 234, 0.15),
      0 6px 12px 0 rgba(23, 210, 224, 0.15);
    transition: box-shadow 0.3s, color 0.3s; /* Add transition properties */
  }
}

.ant-back-top .ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: var(--color-dark);
  // text-align: center;
  background-color: var(--color-primary2);
  border-radius: 40px;
  transition: all 0.2s;
  vertical-align: middle;
}
