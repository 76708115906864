@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-family: "Poppins", sans-serif;
//   outline-color: #a5b4fc;
// }

// body {
//   min-height: 100vh;
//   display: grid;
//   place-items: center;
//   background-color: #e2e8f0;
// }



// input[type="text"],
// input[type="number"],
// input[type="email"],
// input[type="tel"],
// input[type="password"] {
//   font-size: 16px;
// }


// body {
//   touch-action: manipulation;
// }


/*-------------------------
    Forms Styles
-------------------------*/






.signin-text {
  font-size: 14px;
  color: #6b7280;
  margin-bottom: 10px;
}

.coming-soon-area {
  height: auto;
  display: grid;
  place-items: center;
  background-color: #ffffff;
}



.signup-form {
  width: 450px;
  height: auto;
  margin-top: auto;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 2px 4px 8px #6b728040;
  text-align: center;
  padding: 35px;

  @media #{$laptop-device} {
    width: 450px;
  }

  @media #{$smlg-device} {
    width: 450px;
  }

  @media #{$md-layout} {
    width: 400px;
  }

  @media #{$sm-layout} {
    width: 350px;
  }
}

.prompt {
  width: auto;
}

.header {
  margin-bottom: 25px;
}

.header h1 {
  font-weight: bolder;
  font-size: 25px;
  color: var(--color-primary);
  margin-bottom: 5%;
}

.input {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  font-size: 16px;




}

.inputmobile {
  position: relative;
  margin-bottom: 24px;
  width: 100%;
}







.input input {
  width: 100%;
  border: none;
  padding: 4px 40px;
  border-radius: 20px;
  background-color: #f3f4f6;
  color: #1f2937;
  font-size: 16px;
  height: 50px;



}

.input input::placeholder {
  color: #6b7280;
  font-size: 16px;
}

.input i {
  top: 50%;
  width: 36px;
  position: absolute;
  transform: translateY(-50%);
  color: #6b7280;
  font-size: 16px;
}

.eye {
  position: absolute;
  right: 10px;
}

.signup-btn {
  width: 100%;
  height: 50px;
  border: none;
  padding: 2px 0;
  margin: 10px 0;
  border-radius: 20px;
  background-color: var(--color-primary);
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.signup-btn:active {
  background-color: var(--color-primary);
  transition: all 0.3s ease;
}

.social-icons i {
  height: 36px;
  width: 36px;
  line-height: 36px;
  border-radius: 50%;
  margin: 24px 8px 48px 8px;
  //   background-color: gray;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.google {
  width: 45px;
}

.social-icons {
  padding-bottom: 20px;
  width: auto;
  height: auto;
}

// i.fa-facebook-f {
//   background-color: #3b5998;
// }

// i.fa-twitter {
//   background-color: #1da1f2;
// }

// i.fa-google {
//   background-color: #dd4b39;
// }

.signin-link a {
  // color: #000000;
  text-decoration: none;

  :hover {
    color: #000000;
  }

}

.anim {
  height: 100%;
  display: block;
}

.row-signin {
  display: block;
  height: auto;
  //   display: grid;
  place-items: center;
  margin-top: 220px;
  margin-bottom: 150px;

  @media #{$laptop-device} {
    margin-top: 90px;
    margin-bottom: auto;
  }

  @media #{$smlg-device} {
    margin-top: 100px;
    margin-bottom: auto;
  }

  @media #{$md-layout} {
    margin-top: 100px;
    margin-bottom: auto;
  }

  @media #{$sm-layout} {
    margin-top: 150px;
    margin-bottom: auto;
  }

  @media only screen and (min-height: 1500px) {
    margin-top: 300px;
    margin-bottom: 500px;
  }

}

.signin-col {
  padding: 0px;
}

.dialog {
  padding: 10px;
  padding-top: 25px;

}

.dialogButton {
  width: 150px;
  margin-left: 10px;
  align-items: center;

}


.socket {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
}

.hex-brick {
  background: #00ADB4;
  width: 30px;
  height: 17px;
  position: absolute;
  top: 5px;
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.h2a {
  transform: rotate(60deg);
  -webkit-transform: rotate(60deg);
}

.h3a {
  transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.gel {
  height: 30px;
  width: 30px;
  transition: all .3s;
  -webkit-transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center-gel {
  margin-left: -15px;
  margin-top: -15px;
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
}

.c1 {
  margin-left: -47px;
  margin-top: -15px;
}

.c2 {
  margin-left: -31px;
  margin-top: -43px;
}

.c3 {
  margin-left: 1px;
  margin-top: -43px;
}

.c4 {
  margin-left: 17px;
  margin-top: -15px;
}

.c5 {
  margin-left: -31px;
  margin-top: 13px;
}

.c6 {
  margin-left: 1px;
  margin-top: 13px;
}

.c7 {
  margin-left: -63px;
  margin-top: -43px;
}

.c8 {
  margin-left: 33px;
  margin-top: -43px;
}

.c9 {
  margin-left: -15px;
  margin-top: 41px;
}

.c10 {
  margin-left: -63px;
  margin-top: 13px;
}

.c11 {
  margin-left: 33px;
  margin-top: 13px;
}

.c12 {
  margin-left: -15px;
  margin-top: -71px;
}

.c13 {
  margin-left: -47px;
  margin-top: -71px;
}

.c14 {
  margin-left: 17px;
  margin-top: -71px;
}

.c15 {
  margin-left: -47px;
  margin-top: 41px;
}

.c16 {
  margin-left: 17px;
  margin-top: 41px;
}

.c17 {
  margin-left: -79px;
  margin-top: -15px;
}

.c18 {
  margin-left: 49px;
  margin-top: -15px;
}

.c19 {
  margin-left: -63px;
  margin-top: -99px;
}

.c20 {
  margin-left: 33px;
  margin-top: -99px;
}

.c21 {
  margin-left: 1px;
  margin-top: -99px;
}

.c22 {
  margin-left: -31px;
  margin-top: -99px;
}

.c23 {
  margin-left: -63px;
  margin-top: 69px;
}

.c24 {
  margin-left: 33px;
  margin-top: 69px;
}

.c25 {
  margin-left: 1px;
  margin-top: 69px;
}

.c26 {
  margin-left: -31px;
  margin-top: 69px;
}

.c27 {
  margin-left: -79px;
  margin-top: -15px;
}

.c28 {
  margin-left: -95px;
  margin-top: -43px;
}

.c29 {
  margin-left: -95px;
  margin-top: 13px;
}

.c30 {
  margin-left: 49px;
  margin-top: 41px;
}

.c31 {
  margin-left: -79px;
  margin-top: -71px;
}

.c32 {
  margin-left: -111px;
  margin-top: -15px;
}

.c33 {
  margin-left: 65px;
  margin-top: -43px;
}

.c34 {
  margin-left: 65px;
  margin-top: 13px;
}

.c35 {
  margin-left: -79px;
  margin-top: 41px;
}

.c36 {
  margin-left: 49px;
  margin-top: -71px;
}

.c37 {
  margin-left: 81px;
  margin-top: -15px;
}

.r1 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.r2 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.r3 {
  animation-name: pulse00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: pulse00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

.r1>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .2s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .2s;
}

.r2>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .4s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .4s;
}

.r3>.hex-brick {
  animation-name: fade00;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: .6s;
  -webkit-animation-name: fade00;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: .6s;
}

@keyframes pulse00 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(0.01);
    transform: scale(0.01);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fade00 {
  0% {
    background: #00ADB4;
  }

  50% {
    background: #00aeb4fd;
  }

  100% {
    background: #00aeb4fc;
  }
}